.woorank-lead-widget {
  $input-border-color: #ccd1d9;
  $button-background-color : #428bca;
  $button-border-color: #3876ac;
  $button-text-color: #fff;

  input {
    display: block;
    margin: 10px auto;
    min-width: 300px;
    padding: 6px 12px;
    border: 1px solid $input-border-color;
    box-sizing: border-box;
  }

  iframe {
    border: none;
    width: 1px;
    min-width: 100%;
  }

  .woorank-widget-loader {
    background: url(/assets/img/widget/loader.svg) no-repeat;
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }

  button,
  input[type=submit] {
    color: $button-text-color;
    background-color: $button-background-color;
    border-color: $button-border-color;
    border-width: 0 0 3px;
    box-shadow: none;
    margin: 10px auto;
    padding: 12px;
    display: block;

    &:hover {
      background-color: darken($button-background-color, 20%);
      border-color: darken($button-border-color, 20%);
    }
  }
}
